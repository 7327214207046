"use strict";

// Load styles, for Webpack CSS Tree Shaking.
import "./style.scss";

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        // Select the node that will be observed for mutations
        const targetNode = document.querySelector(".block-editor");

        if (targetNode) {
            // Options for the observer (which mutations to observe)
            const config = { attributes: true, subtree: true };

            // Create image elements that will be prepended and appended to the file blocks
            const download = document.createElement('img');
            download.src = '/wp-content/themes/camaco/assets/svg/download.svg';
            const file = document.createElement('img');
            file.src = '/wp-content/themes/camaco/assets/png/download.png';

            // Callback function to execute when mutations are observed
            const callback = (mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === "attributes") {
                        // Prepend the image elements
                        const fileBlocks = Array.from(document.querySelectorAll('.wp-block-file'));
                        fileBlocks.forEach(fileBlock => {
                            const button = fileBlock.querySelector('.wp-block-file__button-richtext-wrapper div');
                            const text = fileBlock.querySelector('.wp-block-file__content-wrapper a');
                
                            if (button && text) {
                                if (!button.querySelector('img')) {
                                    button.append(download.cloneNode(true));
                                }
                                if (!text.querySelector('img')) {
                                    text.prepend(file.cloneNode(true));
                                }
                            }
                        });
                    }
                }
            };

            // Create an observer instance linked to the callback function
            const observer = new MutationObserver(callback);

            // Start observing the target node for configured mutations
            observer.observe(targetNode, config);
        }
    }, 500);
});
