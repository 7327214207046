/**
 * Set featured image text below the featured image
 */
function setFeaturedImageText( OriginalComponent  ) {
	return function( props ) {
		return (
			wp.element.createElement(
				wp.element.Fragment,
				{},
				wp.element.createElement(
					OriginalComponent,
					props
				),
				'Be sure to set a featured image. If one is not set, a fallback image will be used but may not be what you want.'
			)
		);
	}
}
wp.hooks.addFilter( 'editor.PostFeaturedImage', 'camaco/featured-image', setFeaturedImageText );
